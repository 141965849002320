// NavigationData.js
export const NavigationData = [
  {
    title: "Home",
    path: "/",
    cName: "navigation-text",
  },
  {
    title: "About",
    path: "/about",
    cName: "navigation-text",
  },
  {
    title: "Contact",
    path: "/contact",
    cName: "navigation-text",
  },
  {
    title: "Community",
    path: "/community",
    cName: "navigation-text",
  },

  // Example of a new navigation link:
  // {
  // title: "Marketplace,
  // path: "/marketplace",
  // cName: "navigation-text",
  // },
  
];