/* eslint-disable jsx-a11y/img-redundant-alt */
/* Header.js */
import React from 'react';
import './Header.css';

const Header = () => {  
  return (
    <header className="header-container">
      <h1>Berzen Digital Assets</h1>
      <br></br>
      <img src="../../../header_logo_2.jpeg" alt="Berz Website Image" />
    </header>
  );
};

export default Header;